import React from 'react';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Trans, t} from '@lingui/macro';
import { Button, Collapse, notification } from 'antd';
import { getDomain } from '../../../lib/url';
import { API_ENDPOINTS, API_ROOT } from '../../../constants/apiEndpoints';
import SettingInput from './SettingInput';
import 'whatwg-fetch';
import Spinner from '../../graphics/Spinner';
import deepEqual from 'deep-equal';

/**
 * @dusan
 */

class SettingsList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeTab: null,
        };
    }

    handleSubmit = (ev) => {
        ev.preventDefault();

        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const form = ev.target;
        const target = getDomain() + API_ROOT + API_ENDPOINTS.SETTINGS_EDIT;
        const formData = new FormData(form);

        this.setState({loading: true});

        fetch(target, {method: 'POST',  body: formData, credentials: 'include'})
        .then((response) => {
            return response.json()
            .then((json) => {
                this.setState({loading: false});
                if(json.error != null)
                {
                    notification['error']({ 
                        message: json.error.message,
                        duration: 10,
                    });
                }
                else
                {
                    reload([GLOBAL_DATA.SETTINGS]);
                }
            })
        })
        .catch((ex) => {
            this.setState({loading: false});
            notification['error']({ 
                message: ex.toString(),
                duration: 10,
            });
        })
    }

    onTabChange = (newTab) => {
        this.setState({activeTab: newTab});
    }

    render() {
        const settings = this.props[GLOBAL_DATA.SETTINGS];
        const {loading, activeTab} = this.state;
        
        return <Collapse accordion={true} activeKey={activeTab} onChange={this.onTabChange}>
            { settings != null && Array.isArray(settings) ? 
                settings.map((grp, idx) => {
                    return <Collapse.Panel 
                        key={idx} 
                        header={<h4>{grp.group}</h4>}
                    >
                        <form method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                            { grp.settings != null && Array.isArray(grp.settings) ?
                                grp.settings.map((opt, idx2) => {
                                    return <ListItem key={idx2} item={opt}/>
                                }) : null
                            }
                            <Button htmlType='submit' type="primary" loading={loading}>
                                <Trans>Uložiť</Trans>
                            </Button>
                        </form>
                    </Collapse.Panel>
                })
                : null
            }
        </Collapse>;
        
    }

}

export default withDataHOC([GLOBAL_DATA.SETTINGS, GLOBAL_DATA.RELOAD_DATA])(SettingsList);

class ListItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            thisValue: props.item != null ? props.item.value : null
        };
    }

    componentDidUpdate(prevProps) {
        const value = this.props.item != null ? this.props.item.value : null;
        const prevValue = prevProps.item != null ? prevProps.item.value : null;
        if(!deepEqual(value, prevValue))
            this.setState({thisValue: value});
    }

    onChange = (newVal) => {
        this.setState({thisValue: newVal});
    }

    render() {
        const {item} = this.props;
        const {name, value, description, default: def, ...props} = item;
        const {thisValue} = this.state;
        return <div className="p-2 full-size-width">
            <h5>{name}</h5>
            <p>{description} {def != null ? t`Predvolná hodnota: ${def}` : ''}</p>
            <div className="setting-input">
                <SettingInput
                    name={name}
                    value={thisValue}
                    onChange={this.onChange}
                    {...props}
                />
            </div>
        </div>
    }
}