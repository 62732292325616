import React from 'react';
import Helmet from '../project/Helmet';
import { t } from '@lingui/macro';
import SettingsList from './settings/SettingsList';

/**
 * @dusan
 */

class SettingsPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Nastavenia systému`}
            />
            <div className="p-3 full-size-width full-size-height overflow-y-scroll">
                <SettingsList/>
            </div>
        </React.Fragment>;
    }

}

export default SettingsPage;